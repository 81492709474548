<template>
  <Layout
    top-bar-title="Assign CA to several Payments"
    :sections="sections"
    :baseLayoutInfoUrl="'/base-layout-info/'"
  >
    <v-container class="py-0" fluid>
      <v-row>
        <v-col class="pt-0" :cols="errorNotesIds ? 6 : 12">
          <v-expansion-panels v-model="infoPanel" dark>
            <v-expansion-panel class="custom-info-panel">
              <v-expansion-panel-header>
                <div class="d-flex">
                  <v-icon dark class="mr-3">fa-info-circle</v-icon>
                  <strong
                    >The following notes are going to be assign</strong
                  >
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <ul
                  :class="errorNotesIds ? 'normal-columns' : 'expanded-columns'"
                >
                  <li
                    class="redirect"
                    v-for="(id, index) in validNotesIds"
                    :key="index"
                    v-text="id"
                    @click="goToNote(id)"
                  />
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col class="pt-0" cols="6" v-if="errorNotesIds">
          <v-expansion-panels v-model="errorPanel" dark>
            <v-expansion-panel class="custom-error-panel">
              <v-expansion-panel-header>
                <div class="d-flex">
                  <v-icon dark class="mr-3">fa-exclamation-triangle</v-icon>
                  <strong
                    >The following notes are NOT going to be assigned because their 'Remaining (€)' values are 0 or
                    their status ('Rejected', 'Booked') are not valid for assignation</strong
                  >
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <ul class="normal-columns">
                  <li
                    class="redirect"
                    v-for="(id, index) in errorNotesIds"
                    :key="index"
                    v-text="id"
                    @click="goToNote(id)"
                  />
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-card elevation="3" class="mb-3" :loading="localLoading">
        <v-card-title v-text="'Select Customer Activity'" />
        <v-card-text class="pt-0 pb-3">
          <v-row>
            <v-col class="py-0">
              <CrudTable
                v-bind="crudTableConfig"
                :storeModule="storeModule"
                :customOptions="filtersTable"
                :activeSingleSelect="true"
                :showActionsPanel="false"
                :adaptableHeight="true"
                :footerHeight="210"
                @modelEmitter="onSelectItem"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card elevation="3" class="mb-3" :loading="localLoading">
        <v-card-title v-text="'Select Status'" />
        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="3" class="pt-0">
              <v-select
                v-model="status"
                :items="statusList"
                class="pt-0"
                item-text="name"
                item-value="id"
                label="Status"
                return-object
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col class="d-flex justify-end py-0">
          <v-btn
            elevation="3"
            class="mr-3"
            v-text="'Cancel'"
            @click="$router.back()"
          />
          <v-btn
            elevation="3"
            color="primary"
            v-text="'Apply'"
            :disabled="localLoading"
            :loading="localLoading"
            @click="apply"
          />
        </v-col>
      </v-row>
    </v-container>
  </Layout>
</template>

<script>
import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createWarningNotification } from '@/lib/unnotificationsqueue'

export default {
  name: 'AssignPromoDetachmentToSeveralNotes',
  components: { Layout, CrudTable },
  data () {
    return {
      sections: sections,
      storeModule: 'promoDetachmentsForNotes',
      selectedPromoDetachment: null,
      status: null,
      statusList: [],
      localLoading: false,
      infoPanel: 1,
      errorPanel: 1
    }
  },

  created () {
    this.getStatusList()
  },

  computed: {
    crudTableConfig () {
      return this.$store.getters[`${this.storeModule}/getCrudTableConfig`]
    },

    validNotesIds () {
      return this.$route.query?.notes_ids_list?.split(',').map(id => +id)
    },

    errorNotesIds () {
      const erroredNotesIdsStringList = this.$route.query?.errored_notes_ids_list
      return erroredNotesIdsStringList === '' ? null : erroredNotesIdsStringList?.split(',').map(id => +id)
    },
    filtersTable () {
      const id = this.validNotesIds[0]
      const filter = !id ? [] : [id]
      return {
        filterFields: {
          note_id: filter
        }
      }
    }
  },

  methods: {
    async apply () {
      if (!this.selectedPromoDetachment) {
        // eslint-disable-next-line no-return-await
        return await this.$store.dispatch(
          'addNotification',
          createWarningNotification(
            "Select a 'Customer Activity' before applying"
          )
        )
      }
      if (!this.status) {
        // eslint-disable-next-line no-return-await
        return await this.$store.dispatch(
          'addNotification',
          createWarningNotification("Select a 'Status' before applying")
        )
      }
      if (!this.validNotesIds) {
        // eslint-disable-next-line no-return-await
        return await this.$store.dispatch(
          'addNotification',
          createWarningNotification('There are not notes that can be modified')
        )
      }

      try {
        this.localLoading = true
        const body = {
          action_name: 'assign_promo_detachment_to_several_notes',
          extra_args: {
            promo_detachment_id: this.selectedPromoDetachment.id,
            notes_ids_list: this.validNotesIds,
            note_line_status_id: this.status.id
          }
        }
        await apiClient.post('/note-actions/', body)
        this.$router.back()
      } catch (err) {
        console.error(err)
      } finally {
        this.localLoading = false
      }
    },

    async getStatusList () {
      try {
        const statusList = await apiClient.get(
          '/note-field-choices/status/'
        )
        this.statusList = statusList.data
      } catch (err) {
        console.error(err)
      }
    },

    onSelectItem (itemList) {
      this.selectedPromoDetachment = itemList[0] || null
    },

    goToNote (noteId) {
      const noteUrl = this.$router.resolve(`/dido/notes/notes/${noteId}`)
      window.open(noteUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-info-panel {
  background-color: #2196f3 !important;
}

.custom-error-panel {
  background-color: #fb8c00 !important;
}

.normal-columns {
  columns: 4;
}

.expanded-columns {
  columns: 8;
}

.redirect {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
